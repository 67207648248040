import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMarket } from '@polestar/web3-core-react';
import { NavigationDrawer, useNavigationDrawer } from '@polestar/component-warehouse-react';
import { useAccount, useMsal } from '@azure/msal-react';
import { hasClaim } from '../../contexts/utils/user';

const WrappedDrawer = (): React.ReactElement => {
  const history = useHistory();
  const { closeNavigationDrawer } = useNavigationDrawer();
  const { currentMarket } = useMarket();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const superUser = hasClaim(account, ['DocumentHubSuperAccess']);

  const links = [
    {
      label: 'Search',
      onClick: (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        history.push(`/${currentMarket?.locale}/`);
        closeNavigationDrawer();
      }
    },
    {
      label: 'Configuration',
      onClick: (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        history.push(`/${currentMarket?.locale}/config`);
        closeNavigationDrawer();
      }
    }
  ];

  if (superUser) {
    links.push({
      label: 'Data Protection Requests',
      onClick: (e) => {
        e.preventDefault();
        history.push(`/${currentMarket?.locale}/dpr`);
        closeNavigationDrawer();
      }
    });
  }

  return (
    <>
      <NavigationDrawer
        links={links}
      />
    </>
  );
};

export default WrappedDrawer;
